$white: var(--white);
$black: var(--black);

$red-100: var(--red-100);
$red-200: var(--red-200);
$red-300: var(--red-300);
$red-400: var(--red-400);
$red-500: var(--red-500);
$red-600: var(--red-600);
$red-700: var(--red-700);
$red-800: var(--red-800);
$red-900: var(--red-900);

$blue-100: var(--blue-100);
$blue-200: var(--blue-200);
$blue-300: var(--blue-300);
$blue-400: var(--blue-400);
$blue-500: var(--blue-500);
$blue-600: var(--blue-600);
$blue-700: var(--blue-700);
$blue-800: var(--blue-800);
$blue-900: var(--blue-900);

$burgundy-100: var(--burgundy-100);
$burgundy-200: var(--burgundy-200);
$burgundy-300: var(--burgundy-300);
$burgundy-400: var(--burgundy-400);
$burgundy-500: var(--burgundy-500);
$burgundy-600: var(--burgundy-600);
$burgundy-700: var(--burgundy-700);
$burgundy-800: var(--burgundy-800);
$burgundy-900: var(--burgundy-900);

$grey-100: var(--grey-100);
$grey-200: var(--grey-200);
$grey-300: var(--grey-300);
$grey-400: var(--grey-400);
$grey-500: var(--grey-500);
$grey-600: var(--grey-600);
$grey-700: var(--grey-700);
$grey-800: var(--grey-800);
$grey-900: var(--grey-900);

$accent-green: var(--accent-green);
$accent-green-100: var(--accent-green-100);
$accent-green-200: var(--accent-green-200);
$accent-green-300: var(--accent-green-300);
$accent-green-400: var(--accent-green-400);
$accent-green-500: var(--accent-green-500);
$accent-green-600: var(--accent-green-600);
$accent-green-700: var(--accent-green-700);
$accent-green-800: var(--accent-green-800);
$accent-green-900: var(--accent-green-900);

$accent-yellow: var(--accent-yellow);
$accent-yellow-100: var(--accent-yellow-100);
$accent-yellow-200: var(--accent-yellow-200);
$accent-yellow-300: var(--accent-yellow-300);
$accent-yellow-400: var(--accent-yellow-400);
$accent-yellow-500: var(--accent-yellow-500);
$accent-yellow-600: var(--accent-yellow-600);
$accent-yellow-700: var(--accent-yellow-700);
$accent-yellow-800: var(--accent-yellow-800);
$accent-yellow-900: var(--accent-yellow-900);
$accent-orange: var(--accent-orange);
