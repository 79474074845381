/* stylelint-disable */

@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/grid.scss';
@import 'src/styles/spaces.scss';

.heroWrapper {
  position: relative;
  text-align: center;
  padding-top: $spaces-3;
  @include isDevice(desktop) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding-top: 0;
  }
  @include isDevice(desktop) {
    height: 672px;
  }

  @include isDevice(tablet) {
    padding-top: $spaces-10;
  }

  .heroButton {
    display: none;
    @include isDevice(desktop) {
      display: block;
    }
  }

  .heroText {
    box-sizing: border-box;
    flex: 1;
    margin: auto;
    text-align: left;

    @include isDevice(tablet) {
      max-width: grid_units(74);
      margin-left: 0;
    }

    @include isDevice(desktop) {
      max-width: grid_units(74);
      margin-left: 0;
    }

    .title {
      margin-bottom: $spaces-2;
      letter-spacing: normal;
      @include isDevice(tablet) {
        margin-bottom: $spaces-5;
      }

      @include isDevice(desktop) {
        margin-bottom: $spaces-5;
      }
    }

    .subtitle {
      font-weight: 400;
      letter-spacing: normal;
      margin-bottom: $spaces-3;
      @include isDevice(tablet) {
        margin-bottom: $spaces-5;
      }

      @include isDevice(desktop) {
        margin-bottom: $spaces-5;
      }
    }

    .heroButton {
      display: none;
      @include isDevice(desktop) {
        display: inline-flex;
        width: auto;
      }
    }
  }
  .coverImage {
    max-width: 100%;
    @include isDevice(desktop) {
      width: 609px;
      height: 672px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
    }
  }
  .buttonIsFixed {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 24px;
    left: 0;
    @include isDevice(tablet) {
      position: fixed !important;
      z-index: 3;
      left: 50%;
      transform: translateX(-50%);
      bottom: 24px !important;
    }
    @include isDevice(desktop) {
      display: none;
    }
  }
  .heroButtonMobileContainer {
    @include isDevice(mobile) {
      position: absolute;
      right: 0;
      bottom: 24px;
      left: 0;
    }
    @include isDevice(tablet) {
      margin-top: $spaces-6;
      margin-bottom: $spaces-8;
    }
    @include isDevice(desktop) {
      display: none;
    }
    .heroButton {
      display: inline-flex;
      position: relative;
      bottom: 24px;
      width: calc(100% - #{$spaces-6});
      min-width: auto !important;
      @include isDevice(tablet) {
        width: auto;
        bottom: auto;
      }
      @include isDevice(desktop) {
        width: auto;
        bottom: auto;
      }
    }
  }
}

.heroButtonFixed {
  position: fixed !important;
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);
  bottom: 24px !important;
}
