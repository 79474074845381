/* stylelint-disable */

@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/spaces.scss';

.quoteWrapper {
  margin-top: $spaces-8;
  &:first-of-type {
    .data {
      @include isDevice(tablet) {
        padding-right: 0;
        margin-right: 0;
      }
      @include isDevice(desktop) {
        padding-right: 0;
        margin-right: 0;
      }
    }
  }

  @include isDevice(tablet) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: $spaces-10;

    &:nth-child(2n) {
      flex-direction: row;
    }

    &:last-of-type {
      margin-top: $spaces-5;
    }
  }

  @include isDevice(desktop) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: $spaces-10;
    gap: 123px;

    &:nth-child(2n) {
      flex-direction: row;
    }

    &:last-of-type {
      margin-top: $spaces-10;
    }
  }

  .data {
    width: 100%;
    max-width: 544px;
    margin: auto;
    @include isDevice(tablet) {
      padding: 0 $spaces-4;
      flex: 1;
    }
    @include isDevice(desktop) {
      padding: 0 $spaces-4;
      padding-left: 0;
      margin-left: 0;
      flex: auto;
    }
    .quote {
      position: relative;
      margin-top: $spaces-4;
      margin-bottom: $spaces-4;
      padding-bottom: $spaces-4;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50%;
        height: 2px;
        border-bottom: 2px solid $blue-200;
      }
      @include isDevice(desktop) {
        font-size: 28px;
        line-height: 34px;
      }
    }
    .user {
      display: flex;
      align-items: center;
      margin-bottom: $spaces-5;
      gap: 16px;
      .name {
        font-weight: 700;
        @include isDevice(desktop) {
          font-size: 20px;
          line-height: 30px;
        }
      }
      .date {
        color: $blue-300;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        @include isDevice(desktop) {
          font-size: 16px;
          line-height: 24px;
        }
      }
      .avatar {
        width: 52px;
        height: 52px;
        @include isDevice(desktop) {
          width: 64px;
          height: 64px;
        }
      }
    }
  }
  .thumbnail {
    position: relative;
    text-align: center;
    @include isDevice(tablet) {
      flex: 1;
      text-align: left;
      align-self: baseline;
    }
    img {
      max-width: 100%;
    }
  }
}
