@import 'src/styles/utils.scss';

@mixin container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 24px;
}

@mixin containerFluid {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
}

.container {
  @include container;
}

.containerFluid {
  @include containerFluid;
}
