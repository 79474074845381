/* stylelint-disable */

@import 'src/styles/utils.scss';
@import 'src/styles/spaces.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/grid.scss';

.testimonialsContainer {
  padding-top: $spaces-6;
  padding-bottom: $spaces-6;
  @include isDevice(tablet) {
    padding-top: $spaces-10;
    text-align: left;
    padding-bottom: $spaces-10;
  }

  @include isDevice(desktop) {
    padding-top: $spaces-11;
    text-align: left;
    padding-bottom: $spaces-11;
  }
  .title {
    letter-spacing: normal;
    text-align: center;
  }
  .subtitle {
    margin-top: 16px;
    font-size: 20px;
    letter-spacing: normal;
    text-align: center;
    @include isDevice(desktop) {
      margin-top: 24px;
    }
  }
  .testimonialButtonWrapper {
    margin-top: $spaces-4;
    text-align: center;
    @include isDevice(desktop) {
      margin-top: $spaces-11;
    }
    .testimonialButton {
      width: 100%;
      @include isDevice(tablet) {
        width: auto;
      }
      @include isDevice(desktop) {
        width: auto;
      }
    }
  }
}
