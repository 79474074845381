/* stylelint-disable */
@import 'src/styles/utils.scss';
@import 'src/styles/spaces.scss';
@import 'src/styles/palette.scss';

.container {
  position: relative;
  padding: 0;
  border: none;
  outline: inherit;
  background: none;
  color: inherit;
  font: inherit;
  cursor: pointer;

  @include screenIn(desktop) {
    max-width: 572px;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 2px;
    left: 0;
    border-radius: 24px;
    background: rgba(0, 0, 0, 0.16);
  }

  &:hover {
    .popupTrigger {
      background-color: $grey-100;
      i {
        opacity: 0.8;
      }
    }
    &::after {
      background-color: rgba(0, 0, 0, 0.16);
    }
  }

  &:active {
    .popupTrigger {
      background-color: $white;
    }
    &::after {
      background-color: rgba(0, 0, 0, 0.24);
    }
  }

  img {
    border-radius: 24px;
  }
}

.noScroll {
  overflow: hidden;
}

.overlay {
  display: flex;
  position: fixed;
  z-index: 30;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(13, 20, 27, 0.4);
  cursor: pointer;
  .videoIframe {
    max-width: 80%;
    max-height: 70%;
    border-radius: $spaces-2;
  }
  .videoIframe {
    position: relative;
    width: 80%;
    height: 0;
    padding-bottom: 45.25%;

    @include screenIn(tablet) {
      padding-bottom: 38.25%;
    }
  }
  .videoIframe iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: $spaces-2;
  }
}

.popupTrigger {
  display: flex;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  align-items: center;
  justify-content: center;
  width: $spaces-6;
  height: $spaces-6;
  transform: translate(-50%, -50%);
  border-radius: 60%;
  background-color: $white;
}
