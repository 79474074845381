@function grid_units($units) {
  @return $units * 8px;
}

@function transition_all($time) {
  @return all $time * 1ms cubic-bezier(0.4, 0, 0.2, 1);
}

@mixin screenIn($device) {
  @if $device == desktop {
    @media screen and (min-width: 1200px) {
      @content;
    }
  } @else if $device == mobile {
    @media screen and (max-width: 767px) {
      @content;
    }
  } @else if $device == tablet {
    @media screen and (min-width: 768px) {
      @content;
    }
  } @else {
    @error "That device size doesn't exist";
  }
}

@mixin isDevice($device) {
  @if $device == desktop {
    @media (min-width: 1280px) {
      @content;
    }
  } @else if $device == tablet {
    @media screen and (min-width: 481px) and (max-width: 1280px) and (orientation: portrait),
      screen and (min-width: 907px) and (max-width: 1279px) and (orientation: landscape) {
      @content;
    }
  } @else if $device == mobile {
    @media screen and (max-width: 480px) and (orientation: portrait), screen and (max-width: 906px) and (orientation: landscape) {
      @content;
    }
  } @else {
    @error "That device size doesn't exist";
  }
}

$max-layout-width: 1120px;
