$spaces-1: var(--spaces-1);
$spaces-2: var(--spaces-2);
$spaces-3: var(--spaces-3);
$spaces-4: var(--spaces-4);
$spaces-5: var(--spaces-5);
$spaces-6: var(--spaces-6);
$spaces-8: var(--spaces-8);
$spaces-7: var(--spaces-7);
$spaces-10: var(--spaces-10);
$spaces-11: var(--spaces-11);

$headerSpaceDesktop: 64px;
