/* stylelint-disable */

@import 'src/styles/utils.scss';
@import 'src/styles/spaces.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/grid.scss';

.howWorksSection {
  padding-top: $spaces-6;
  padding-bottom: $spaces-5;
  background-color: $grey-100;
  @include isDevice(tablet) {
    padding-top: $spaces-10;
    padding-bottom: $spaces-8;
  }
  @include isDevice(desktop) {
    padding-top: 100px;
    padding-bottom: $spaces-10;
  }

  .howWorksContainer {
    overflow: hidden;
  }

  .title {
    letter-spacing: normal;
    text-align: center;
  }
  .subtitle {
    margin-top: 16px;
    font-size: 20px;
    letter-spacing: normal;
    text-align: center;
    @include isDevice(desktop) {
      margin-top: 24px;
      margin-bottom: 80px;
    }
  }

  .featuresWrapper {
    overflow: hidden;
    flex-wrap: wrap;
    @include screenIn(tablet) {
      display: flex;
      gap: $spaces-5;
      margin-top: $spaces-5;
    }
    .featureRow {
      overflow: hidden;
      flex: 1;
      @include isDevice(tablet) {
        display: flex;
        gap: $spaces-8;
      }
      @include isDevice(desktop) {
        display: flex;
        gap: $spaces-5;
      }
    }
  }

  .howWorksButtonWrapper {
    margin-top: $spaces-3;
    margin-bottom: $spaces-3;
    text-align: center;
    display: flex;
    justify-content: center;
    @include isDevice(tablet) {
      margin-top: $spaces-7;
      margin-bottom: $spaces-1;
    }
    @include isDevice(desktop) {
      margin-top: $spaces-7;
      margin-bottom: $spaces-1;
    }
    .howWorksButton {
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      min-width: auto;
      text-rendering: geometricprecision;
      @include isDevice(tablet) {
        width: auto;
      }
      @include isDevice(desktop) {
        width: auto;
      }
    }
  }
}
