/* stylelint-disable no-descending-specificity */

@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';

.big {
  height: grid_units(7);
  padding-right: grid_units(4);
  padding-left: grid_units(4);
  font-size: 14px;
}

.small {
  width: auto;
  height: grid_units(5);
  padding-right: grid_units(2);
  padding-left: grid_units(2);
  font-size: 12px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: grid_units(5);
  height: grid_units(5);
  padding: 0;
  font-size: 14px;
  letter-spacing: 0;
}

.button {
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  transition: background-color 200ms ease;
  border: none;
  border-radius: grid_units(0.5);
  color: $white;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  @include screenIn(desktop) {
    min-width: 370px;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.red {
  background-color: $red-500;

  &:hover {
    background-color: $red-400;
  }

  &:active {
    background-color: $red-600;
  }

  &.disabled {
    background-color: $red-200;
  }
}

.blue {
  background-color: $blue-600;

  &:hover {
    background-color: $blue-500;
  }

  &:active {
    background-color: $blue-700;
  }

  &.disabled {
    background-color: $blue-200;
  }
}

.grey {
  background-color: $grey-500;

  &:hover {
    background-color: $grey-400;
  }

  &:active {
    background-color: $grey-600;
  }

  &.disabled {
    background-color: $grey-200;
  }
}

.burgundy {
  background-color: $burgundy-500;

  &:hover {
    background-color: $burgundy-400;
  }

  &:active {
    background-color: $burgundy-600;
  }

  &.disabled {
    background-color: $burgundy-200;
  }
}

.green {
  background-color: $accent-green-500;

  &:hover {
    background-color: $accent-green-400;
  }

  &:active {
    background-color: $accent-green-600;
  }

  &.disabled {
    background-color: $accent-green-200;
  }
}

.yellow {
  background-color: $accent-yellow-500;

  &:hover {
    background-color: $accent-yellow-400;
  }

  &:active {
    background-color: $accent-yellow-600;
  }

  &.disabled {
    background-color: $accent-yellow-200;
  }
}

.lightBlue {
  background-color: $blue-200;

  &:hover {
    background-color: $blue-300;
  }

  &:active {
    background-color: $blue-400;
  }

  &.disabled {
    background-color: $blue-100;
  }
}

.outlineBlue {
  border: 1px solid $blue-600;
  background-color: $white;
  color: $blue-600;

  &:hover {
    border-color: $blue-500;
  }

  &:active {
    border-color: $blue-700;
  }

  &.disabled {
    border-color: $blue-200;
  }
}

.outlineRed {
  border: 1px solid $red-500;
  background-color: $white;
  color: $red-500;

  &:hover {
    border-color: $red-400;
    color: $red-400;
  }

  &:active {
    border-color: $red-600;
    color: $red-600;
  }

  &.disabled {
    border-color: $red-200;
  }
}

.fullwidth {
  width: 100%;
  min-width: unset;
}
