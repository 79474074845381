/* stylelint-disable */

@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';

.featureContainer {
  display: flex;
  flex-direction: row;
  @include isDevice(desktop) {
    display: block;
  }
  .featureStepper {
    position: relative;
    box-sizing: border-box;
    max-height: 100%;

    .step {
      display: flex;
      position: relative;
      z-index: 2;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      margin-top: 6px;
      margin-right: 16px;
      color: $red-500;
      font-weight: 700;
      letter-spacing: normal;
      font-size: 20px;
      &::before {
        content: ' ';
        position: absolute;
        z-index: -2;
        top: -20px;
        right: 0;
        bottom: -20px;
        left: 0;
        background-color: $grey-100;
        @include isDevice(desktop) {
          right: -20px;
          left: -20px;
        }
      }
      &::after {
        content: ' ';
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 3px solid $red-500;
        border-radius: 100%;
        background-color: $grey-100;
      }
    }

    .border {
      position: absolute;
      left: 16px;
      width: 0;
      height: 100%;
      border-left: 2px dashed $blue-200;
      @include isDevice(mobile) {
        height: 160%;
      }
      @include isDevice(tablet) {
        visibility: hidden;
      }
      @include isDevice(desktop) {
        visibility: visible;
        top: 50%;
        right: 50px;
        left: 0;

        width: calc(100% + 50px);

        height: 0;
        transform: translateY(-50%);
        border-top: 2px dashed $blue-200;
      }
    }
  }

  .image {
    margin-top: 32px;
    margin-bottom: 24px;
    margin-left: 54px;
    z-index: 1;
    @include isDevice(mobile) {
      width: auto;
      height: 130px;
    }
    @include isDevice(desktop) {
      margin-left: 0;
      max-width: 150px;
    }
    @include isDevice(tablet) {
      width: auto;
      height: 130px;
    }
  }
  .featureData {
    padding-bottom: 16px;
    .title {
      letter-spacing: normal;
      @include isDevice(desktop) {
        margin: 24px 0;
        font-size: 28px;
        line-height: 34px;
      }
    }
    .description {
      font-weight: 400;
      letter-spacing: normal;
      @include isDevice(desktop) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

.hideSteps {
  .featureStepper {
    display: none;
  }
  .image {
    @include isDevice(mobile) {
      margin-left: 0;
    }
    @include isDevice(tablet) {
      margin-left: 0;
    }
  }
}

.inMobile {
  @include isDevice(desktop) {
    padding-left: 16px;
    .border {
      visibility: hidden !important;
    }
    .image {
      width: auto;
      height: 98px;
    }
  }
}

.featureWrapper {
  flex: 1;
  @include screenIn(tablet) {
    flex: auto;
    width: 333px;
  }
  @include screenIn(desktop) {
    flex: 1;
  }
}
.firstChild .featureContainer:first-child .border {
  visibility: hidden;
}
.lastChild .featureContainer:last-child .border {
  visibility: hidden;
}
.lastInTwoRows .border {
  visibility: hidden !important;
}
